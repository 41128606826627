<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="ranges"
  />
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'

function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default {
  name: 'DashboardChart',
  components: {
    CChartLine
  },
  props: {
    graph: Object
  },
  data () {
    return {
      invoices: [],
      payments: [],
      ranges: [],
      max: 1000
    }
  },
  computed: {
    defaultDatasets () {
      const brandSuccess = getStyle('success') || '#4dbd74'
      const brandInfo = getStyle('info') || '#20a8d8'
      const brandDanger = getStyle('danger') || '#f86c6b'

      this.invoices = [];
      this.payments = [];
      this.ranges = [];

      let data = this.data;

      let count_invoices = 12;
      let total_invoices = 0;
      let average_invoices = 0;
      let total_payments = 0;

      for (let i = 0; i <= 12; i++) {
        if(this.graph.ranges[i] != undefined){
          this.ranges.push(this.graph.ranges[i].name);
        }
      }

      for (let i = 0; i <= 12; i++) {
        if(this.graph.totals[i] != undefined && this.graph.totals[i].invoice != undefined){          
          this.invoices.push(this.$options.filters.decimal(this.graph.totals[i].invoice));
          this.payments.push(this.$options.filters.decimal(this.graph.totals[i].payment));

          total_invoices += this.graph.totals[i].invoice*1;
          total_payments += this.graph.totals[i].payment*1;

          if(this.graph.totals[i].invoice*1 > this.max){
            this.max = this.graph.totals[i].invoice*1;
          }

          if(this.graph.totals[i].payment*1 > this.max){
            this.max = this.graph.totals[i].payment*1;
          }
        }
      }

      average_invoices = total_invoices / 12;

      let top = 10;

      if(this.max > 0){
        top = this.max
        while(top < this.max){
          top = top * 10;
        }
        
        this.max = this.max + top / 10;
      }

      this.$emit("totals", [count_invoices, this.$options.filters.decimal(total_invoices), this.$options.filters.decimal(average_invoices), this.$options.filters.decimal(total_payments)]);

      return [
        {
          label: 'Total Facturado',
          backgroundColor: 'transparent',
          borderColor: brandSuccess,
          pointHoverBackgroundColor: brandSuccess,
          borderWidth: 2,
          data: this.invoices
        },
        {
          label: 'Total Pagado',
          backgroundColor: hexToRgba(brandInfo, 10),
          borderColor: brandInfo,
          pointHoverBackgroundColor: brandInfo,
          borderWidth: 2,
          data: this.payments
        },
      ]
    },
    defaultOptions () {
      return {

        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: Math.ceil(this.max / 5),
              max: this.max
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  }
}
</script>
